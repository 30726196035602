import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import NuvoImage from "../components/NuvoImage"
import ImageMeta from "../components/ImageMeta"
import { MarkdownViewer } from "../components/Markdown"
import Seamless from "../components/SeamlessEmbed"
import { Section, Container } from "../components/Containers"

const GetInvolved = ({ pageContext, data, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { hero, form, content, bodyImage } = post

  return (
    <Layout>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={null}
        pathname={location.pathname}
      />

      <div className="get-involved-page">
        <div className="get-involved__grid">
          <div
            className="get-involved__grid--left"
            style={{
              backgroundImage: `url(https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/${hero.image})`
            }}>
            {/* <ImageMeta
              cloudName="nuvolum"
              width="auto"
              publicId={hero.image}
              className="get-involved__desktop-image is-hidden-mobile"
            /> */}
            <NuvoImage
              publicId={hero.imageMobile}
              wrapperClassName="get-involved__mobile-image"
            />
          </div>

          <div className="get-involved__grid--right">
            <Section smallSpacing>
              <Container>
                <h1>{form.heading}</h1>
                <MarkdownViewer markdown={form.blurb} className="mb-4" />
                <Seamless
                  src="https://secureform.seamlessdocs.com/f/1b9ae4f3a27370ef6a121c41cb612698?embedded=true"
                  className="container-overflow-y--mobile"
                />
              </Container>
            </Section>
          </div>
        </div>

        <div className="get-involved__grid">
          <div className="get-involved__grid--body-content">
            <Section colorBack>
              <Container>
                <MarkdownViewer markdown={content} />
              </Container>
            </Section>
          </div>
          <div
            className="get-involved__grid--body-image"
            style={{
              backgroundImage: `url(https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/${bodyImage})`
            }}></div>
        </div>
      </div>
    </Layout>
  )
}

GetInvolved.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const getInvolvedPageQuery = graphql`
  query getInvolvedQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          image
          imageMobile
        }
        form {
          heading
          blurb
        }
        content
        bodyImage
      }
    }
  }
`

export default GetInvolved
